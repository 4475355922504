<template>
  <!-- 代理商列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <a-form-item label="卡号">
                    <a-input v-model="queryParam.cardNo" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="是否激活">
                    <a-select v-model="queryParam.liveStatus" allow-clear placeholder="请选择">
                      <a-select-option :value="1">否</a-select-option>
                      <a-select-option :value="2">是</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 按钮 -->
                <a-col :md="12" :sm="24">
                  <a-button type="primary" @click="exportData">导出</a-button>
                  <a-button type="primary" style="margin-left: 8px" @click="batchActivation">批量激活</a-button>
                  <a-button type="primary" style="margin-left: 8px" @click="$refs.addForm.add()">生成兑换卡</a-button>

                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                  <a-button style="margin-left: 8px" type="primary" @click="initTableData">搜索</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
            :row-selection="rowSelection"
          >
            <!-- 状态插槽 -->
            <span slot="liveNo" slot-scope="text, record">
              <a-tag :color="!record.liveNo ? 'green' : 'red'">
                {{ !record.liveNo ? '否' : '是' }}
              </a-tag>
            </span>

            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a-divider type="vertical" v-if="!record.liveNo" />
              <a-popconfirm
                placement="topRight"
                title="确认激活？"
                @confirm="() => activate([record.id])"
                v-if="!record.liveNo"
              >
                <a>激活</a>
              </a-popconfirm>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <add-form @ok="initTableData" ref="addForm"></add-form>
  </div>
</template>

<script>
import detailForm from './detailForm.vue'
import addForm from './addForm'

import { exchangeIntegralInvoicePage, exchangeIntegralLiveBatch } from '@/api/modular/mallLiving/recharge'
const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '卡号',
    width: '100px',
    dataIndex: 'cardNo',
    align: 'center',
  },
  {
    title: '面值',
    width: '100px',
    dataIndex: 'integral',
    align: 'center',
  },
  {
    title: '密码',
    width: '130px',
    dataIndex: 'pwd',
    align: 'center',
  },
  {
    title: '是否激活',
    dataIndex: 'isActivate',
    width: '100px',
    align: 'center',
    scopedSlots: { customRender: 'liveNo' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '150px',
    align: 'center',
  },
  {
    title: '激活码',
    dataIndex: 'liveNo',
    width: '150px',
    align: 'center',
  },
  {
    title: '激活时间',
    dataIndex: 'liveTime',
    width: '150px',
    align: 'center',
  },

  {
    title: '操作',
    width: '130px',
    align: 'center',
    fixed: 'right',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      videoTypeList: [], //视频分类数据
      provinceList: [], //省份列表
      cityList: [], //市区列表
      selectedRows: [],
      selectedRowKeys: [],
    }
  },
  components: {
    detailForm,
    addForm,
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          console.log('###', selectedRowKeys, selectedRows)
          this.selectedRows = selectedRows
          this.selectedRowKeys = selectedRowKeys
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.liveNo, // Column configuration not to be checked
            name: record.cardNo,
          },
        }),
      }
    },
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    batchActivation() {
      if (!this.selectedRowKeys.length) {
        return this.$message.info('请选择需要激活的兑换卡！')
      }

      this.activate(this.selectedRowKeys)
    },
    exportData() {
      this.axios
        .post(`/exchangeIntegral/export`, this.queryParam, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '兑换卡' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    activate(ids) {
      exchangeIntegralLiveBatch({ ids })
        .then((res) => {
          if (res.success) {
            this.$message.success('兑换卡激活成功')
            this.selectedRowKeys = []
            this.selectedRows = []
            this.getList()
          }
        })
        .catch((error) => {
          this.$message.error(error.message || '兑换卡激活失败')
        })
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      exchangeIntegralInvoicePage(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
